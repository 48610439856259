import React from "react"
import { graphql } from "gatsby"
import Img from "gatsby-image"
import Layout from "../../components/layout/layout"
import SEO from "../../components/seo/seo"
import Banner from "../../components/banner/banner"
import Links from "../../components/about/links/links"

export default function About({ data }) {
  return (
    <Layout>
      <SEO title="About Us" />
      <Banner
        image={
          <Img
            fluid={data.placeholderImage.childImageSharp.fluid}
            style={{ height: "100%" }}
          />
        }
        title="About Us"
      />
      <div className="width75">
        <h1 className="title">JJ Law Chambers</h1>
        <Links type="about" />
        <div className="ribbon-top">
          <p>
            JJ Law Chambers is a Recognised Body regulated by the Solicitors
            Regulation Authority.
          </p>
          <p>
            Through a personal and pro-active approach JJ Law Chambers have
            grown to become a trusted source of legal advice in the local
            community as well as offering services to clients throughout the
            country. We have a large team of solicitors who are able to bring
            together a broad range of expertise across the legal services
            spectrum.
          </p>
          <p>
            With the emphasis on team work, the talents and skills of our
            professionals enable us to provide an efficient legal service in a
            friendly, approachable and sensitive manner.
          </p>
          <p>
            We pride ourselves on our open and innovative approach in providing
            clear and straight forward advice, taking the time to understand
            your needs and work with you to find the right solution, providing a
            cost-effective, competitively priced service.
          </p>
          <p>
            At JJ Law we are dedicated to providing a professional and quality
            service whether you need general advice, assistance at police
            stations and at court. Our offices are open on Monday to Friday from
            9am to 5.30pm but we are contactable 24 hours a day, 7 days a week.
          </p>
          <p>
            Home visits can be arranged for those with a mobility problem and a
            range of languages are spoken within our offices and where needed
            interpreters can be arranged.
          </p>
          <p>
            We recognize that when you instruct a solicitor you are making a
            very important decision. You need to be assured that the firm you
            instruct can not only do the job, but can do it efficiently, keeping
            you informed and giving you the best advice available.
          </p>
        </div>
      </div>
    </Layout>
  )
}

export const query = graphql`
  query {
    placeholderImage: file(relativePath: { eq: "lawers.jpg" }) {
      childImageSharp {
        fluid(maxWidth: 1920) {
          ...GatsbyImageSharpFluid
        }
      }
    }
  }
`
