import React from "react"
import { Link } from "gatsby"
import styles from "./links.module.css"

export default function Links({ type }) {
  return (
    <div className={styles.links}>
      <Link to="/about" className={styles.link} activeClassName={styles.active}>
        About Us
      </Link>

      <Link
        to="/about/charges-and-fees"
        className={styles.link}
        activeClassName={styles.active}
      >
        Charges and Fees
      </Link>

      <Link
        to="/about/our-team"
        className={styles.link}
        activeClassName={styles.active}
      >
        Our Team
      </Link>

      <Link
        to="/about/complaints-procedure"
        className={styles.link}
        activeClassName={styles.active}
      >
        Complaints Procedure
      </Link>
    </div>
  )
}
